import Config from '../config';

export const config = {
    login: `${Config.site_api}/v1/user/login`,
    register: `${Config.site_api}/v1/user/register`,
    updateDetail: `${Config.site_api}/v1/user/updateDetail`,
    getProfile: `${Config.site_api}/v1/user/profile`,
    getGlobalSetting: `${Config.site_api}/v1/user/getGlobalSetting`,

    // admin
    adminLogin: `${Config.site_api}/v1/admin/login`,
    getUserList: `${Config.site_api}/v1/admin/userList`,
    updateGloablSetting: `${Config.site_api}/v1/admin/updateGlobalSetting`,
    changeStatus: `${Config.site_api}/v1/admin/changeStatus`,
    deleteAccount: `${Config.site_api}/v1/admin/deleteAccount`,
}

import React, {Fragment, useContext, useEffect, useState} from 'react';
import Navbar3 from '../../components/Navbar3/Navbar3';
import PageTitle from '../../components/pagetitle/PageTitle';
import Footer2 from '../../components/footer2/Footer2';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Logo from '../../images/logo.png'
import UserContext from '../../components/ContextApi/UserContext';
import UserDetails from './UserDetails';
import HeaderSection from './HeaderSection';
import { config } from '../../utils/apiUrl';
import API from '../../utils/apiCalling';
import { toast } from 'react-toastify';
import Navbar2 from '../../components/Navbar2/Navbar2';
import Navbar5 from '../../components/Navbar5/Navbar5';
import Swal from 'sweetalert2'

import { clearLocalData, getLocalDataAsObject } from '../../utils/CoustomStorage';
import { imgUrl } from '../../config/env';

const AdminDashboardSetting = () => {
    const api = new API()
    const [globalDetails, setGlobalDetails] = useState({
        email: '',
        mobile : '',
        addressLine1: '',
        addressLine2: '',
        website: '',
        description: '',
        heading1: '',
        heading2: '',
        heading3: '',
        heading4: '',
        heading5: '',
        qrCodeImage: '',
        logo: '',
        upi: '',

    })
    const [token, setToken] = useState(false)
     const [qrCodeImage, setQrCodeImage] = useState("")
    const [logo, setLogo] = useState("")

    useEffect(()=> {
        let token= getLocalDataAsObject('admin-token')
        if(!token) {
            clearLocalData()
            return window.location.replace('/');
        } 
        setToken(true)
        getGlobalDetails()
    },[])
  

    // const { user } = useContext(UserContext);

    const getGlobalDetails = async() => {
            const response = await api.get(config.getGlobalSetting)
            console.log("response is", response)
            if (response.code == 200) {
                setGlobalDetails(response?.data)
                console.log(response)
            } else {
                toast.success('Something wen wrong !');
            }
    };
    const updateGlobalDetails = async() => {
        const formData = new FormData();
        formData.append('email', globalDetails?.email || "");
        formData.append('mobile', globalDetails?.mobile || "");
        formData.append('addressLine1', globalDetails?.addressLine1 || "");
        formData.append('addressLine2', globalDetails?.addressLine2 || "");
        formData.append('website', globalDetails?.website || "");
        formData.append('description', globalDetails?.description || "");
        formData.append('heading1', globalDetails?.heading1 || "");
        formData.append('heading2', globalDetails?.heading2 || "");
        formData.append('heading3', globalDetails?.heading3 || "");
        formData.append('heading4', globalDetails?.heading4  || "");
        formData.append('heading5', globalDetails?.heading5  || "");
        formData.append('qrCodeImage', qrCodeImage || "");
        formData.append('logo', logo  || "");
        formData.append('upi', globalDetails?.upi || "");
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure you want to update this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes!"
          }).then(async(result) => {
            if (result.isConfirmed) {
            const response = await api.postUpload(config.updateGloablSetting, formData)
            if (response.code == 200) {
                setQrCodeImage("")
                setLogo("")
                  Swal.fire({
                    title: "Updated!",
                    text: "Successfully updated.",
                    icon: "success"
                  });
                  getGlobalDetails()
                console.log(response)
            } else {
                toast.success('Something wen wrong !');
            }
            }
          });
};
    const onChangeHandler  = (e) => {
        setGlobalDetails({
            ...globalDetails,
            [e.target.name] : e.target.value
        })
    }

    const onChangeHandlerFile = (e) => {
        if(e.target.name == 'qrCodeImage') {
            setQrCodeImage(e.target.files[0])
        } else if(e.target.name == 'logo') {
            setLogo(e.target.files[0])
        }
        console.log(e.target.files)
        setGlobalDetails({ ...globalDetails, [e.target.name]: e.target.files[0] })
    }
    return(
        <Fragment>
            {token &&
            <>
             <Navbar5 Logo={Logo}/>
            <PageTitle pageTitle={'Admin Dashboard Setting'} pagesub={'admin-dashboard-setting'}/> 
            <div>
                <h1 align="center">Admin Dashboard Global Setting</h1>
                <div style={{width: '80%', margin: 'auto', marginTop: '3%'}}>
                <form>
                    <div class="form-group">
                        <label for="">Email</label>
                        <input name='email' value={globalDetails?.email} onChange={onChangeHandler} type="text" class="form-control" id="" aria-describedby="emailHelp" placeholder="Enter email"/>
                    </div>
                    <div class="form-group">
                        <label for="">Mobile</label>
                        <input name='mobile' value={globalDetails?.mobile} onChange={onChangeHandler} type="mobile" class="form-control" placeholder="Enter mobile"/>
                    </div>
                    <div class="form-group">
                        <label for="">Address Line 1</label>
                        <input name='addressLine1' value={globalDetails?.addressLine1} onChange={onChangeHandler} type="text" class="form-control" placeholder="Enter address line1"/>
                    </div>
                    <div class="form-group">
                        <label name='addressLine2'>Address Line 2</label>
                        <input type="text" name='addressLine2' value={globalDetails?.addressLine2} onChange={onChangeHandler} class="form-control" placeholder="Enter address line2"/>
                    </div>
                    <div class="form-group">
                        <label for="">Website</label>
                        <input name='website' value={globalDetails?.website} onChange={onChangeHandler} type="text" class="form-control" placeholder="Enter website"/>
                    </div>
                    <div class="form-group">
                        <label for="">Description</label>
                        <input name='description' value={globalDetails?.description} onChange={onChangeHandler} type="text" class="form-control" placeholder="Enter description"/>
                    </div>
                    <div class="form-group">
                        <label for="">Heading 1</label>
                        <input name='heading1' value={globalDetails?.heading1} onChange={onChangeHandler} type="text" class="form-control" placeholder="Enter heading1"/>
                    </div>
                    <div class="form-group">
                        <label for="">Heading 2</label>
                        <input name='heading2' value={globalDetails?.heading2} onChange={onChangeHandler} type="text" class="form-control" placeholder="Enter heading2"/>
                    </div>
                    <div class="form-group">
                        <label for="">Heading 3</label>
                        <input name='heading3' value={globalDetails?.heading3} onChange={onChangeHandler} type="text" class="form-control" placeholder="Enter heading3"/>
                    </div>
                    <div class="form-group">
                        <label for="">Heading 4</label>
                        <input name='heading4' value={globalDetails?.heading4} onChange={onChangeHandler} type="text" class="form-control" placeholder="Enter heading4"/>
                    </div>
                    <div class="form-group">
                        <label for="">Heading 5</label>
                        <input name='heading5' value={globalDetails?.heading5} onChange={onChangeHandler} type="text" class="form-control" placeholder="Enter heading5"/>
                    </div>
                    <div class="form-group">
                        <label for="">UPI</label>
                        <input name='upi' value={globalDetails?.upi} onChange={onChangeHandler} type="text" class="form-control" placeholder="Enter upi"/>
                    </div>
                    <div className='row'>

                    <div class="col-md-6 form-group">
                        <label for="">Logo Image</label>
                        <input type="file" name='logo'  onChange={onChangeHandlerFile} class="form-control"/>
                    </div>
                    <div class="col-md-6 form-group">
                    <img src={globalDetails?.logo ? `${imgUrl}/${globalDetails?.logo}` : '' } alt="" height="80px" />
                    </div>
                    </div>
                    <div className='row'>

                    <div class="col-md-6 form-group">
                        <label for="">QR Image</label>
                        <input type="file" name='qrCodeImage' onChange={onChangeHandlerFile} class="form-control"/>
                    </div>
                    <div class="col-md-6 form-group">
                       <img src={globalDetails?.qrCodeImage ? `${imgUrl}/${globalDetails?.qrCodeImage}` : '' } alt="" height="80px" />
                    </div>
                    </div>

                <button type="button" class="btn btn-primary" onClick={updateGlobalDetails}>Submit</button>
                </form>
                </div>
            </div>
            <Scrollbar/>
            </>
            }
        </Fragment>
    )
};
export default AdminDashboardSetting;



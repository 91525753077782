import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap JavaScript

const HomePageSlider = () => {
  return (
    <>
    <div className="HomePageSlider">
      <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" style={{marginTop:'90px'}}>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src="../images/banner1.png" className="d-block w-100" alt="No image found" />
          </div>
          <div className="carousel-item">
            <img src="../images/banner2.png" className="d-block w-100" alt="No image found" />
          </div>
          <div className="carousel-item">
            <img src="../images/banner3.png" className="d-block w-100" alt="No image found" />
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      </div>
    </>
  );
}

export default HomePageSlider;


// import React from 'react'

// const HomePageSlider = () => {
//   return (
//     <>
//     <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
//     <div class="carousel-inner">
//       <div class="carousel-item active">
//         <img src="../images/banner1.png" class="d-block w-100" alt="No image found"/>
//       </div>
//       <div class="carousel-item">
//         <img src="../images/banner2.png" class="d-block w-100" alt="No image found"/>
//       </div>
//       <div class="carousel-item">
//         <img src="../images/banner3.png" class="d-block w-100" alt="No image found"/>
//       </div>
//     </div>
//     <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
//       <span class="carousel-control-prev-icon" aria-hidden="true"></span>
//       <span class="visually-hidden">Previous</span>
//     </button>
//     <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
//       <span class="carousel-control-next-icon" aria-hidden="true"></span>
//       <span class="visually-hidden">Next</span>
//     </button>
//   </div>

//     </>
//   )
// }

// export default HomePageSlider

import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function AdminLoginModal({adminLogin}, args) {
  const [modal, setModal] = useState(true);
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const toggle = () => {
    setModal(!toggle)
  }
  return (
    <div>
      <Modal className="modal-dialog-centered" isOpen={modal} toggle={toggle} {...args}>
        <ModalHeader toggle={toggle}>Admin Login</ModalHeader>
        <ModalBody>
        <form>
          <div class="form-group mb-3">
            <label for="email">Email address</label>
            <input type="email" value={email} onChange={(e)=>setEmail(e.target.value)} class="form-control" id="email" aria-describedby="emailHelp" placeholder="Enter email"/>
            <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
          </div>
          <div class="form-group mb-3">
            <label for="exampleInputPassword1">Password</label>
            <input type="password" value={password} onChange={(e)=>setPassword(e.target.value)} class="form-control" id="exampleInputPassword1" placeholder="Password"/>
          </div>
        </form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <Button onClick={()=>adminLogin(email, password)} color="primary" >
            Login
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AdminLoginModal;
import React, { useState } from 'react';

const UserDetailsUpdateForm = ({isPaid, label, limit, item, updateUserDetails, isFeePaidFor, amountFor}) => {
    const [amount, setAmount] = useState(limit)
return( 
    <div class="login-box">
        <form>
            <div class="user-box">
                <input type="text" onChange={(e)=>setAmount(e.target.value)} value={amount} name="" required=""/>
                <label>{label} : <label style={{color: isPaid ? 'blue' : 'red'}}>{isPaid ? "Paid" : "Not Paid"}</label></label>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <a onClick={()=>updateUserDetails(item, amountFor, amount)}>
                 Update
                <span></span>
                </a>
                <a onClick={()=>updateUserDetails(item, isFeePaidFor, !isPaid)}>
                 {isPaid ? 'Not Paid' : 'Paid'}
                <span></span>
                </a>
            </div>
        </form>
    </div>
    )
}
export default UserDetailsUpdateForm
import React from 'react';
import ContactForm from '../ContactFrom/ContactForm'
import { Button2 } from '../common/Button2';
import { clearLocalData } from '../../utils/CoustomStorage';
import { useNavigate } from 'react-router-dom';
const Contactpage = ({user}) => {
    const navigate=useNavigate();

    const logout = () => {
        clearLocalData()
        window.location.reload();
    }

    const redirectToApply = () =>{
        navigate('/apply')
    }
    
    return (
        <section className="wpo-contact-pg-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-12">
                        <div className="wpo-contact-title">
                            <h2>Register Now to Apply Your Free Credit Card?</h2>
                            <p>Apply today and get special offer.</p>
                        </div>
                        <div className="wpo-contact-form-area apply_contact_form" style={{
    backgroundImage: "linear-gradient(90deg, rgba(26,56,76,1) 0%, rgba(0,47,104,0.9305847338935574) 48%, rgba(26,56,76,1) 100%)",
    backgroundSize: 'cover', // or 'contain', depending on your preference
    marginBottom: '50px',
    borderRadius: '50px'
}}>
                           {user && user._id ?
                           <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                           <h3 style={{color: 'white'}}>Logout to Apply New Application </h3>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '40px', marginTop: '50px'}}>
                            <Button2 clickHandler={logout} label={"Logout"}></Button2>
                            <Button2 clickHandler={redirectToApply} label={"Go To In-Progress Application"}></Button2>
                            </div>

                           </div>
                           :
                           <ContactForm />
                        }
                        </div>
                    </div>
                </div>
            </div>
            {/* <section className="wpo-contact-map-section">
                <div className="wpo-contact-map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.9147703055!2d-74.11976314309273!3d40.69740344223377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew+York%2C+NY%2C+USA!5e0!3m2!1sen!2sbd!4v1547528325671"></iframe>
                </div>
            </section> */}
        </section>
    )

}

export default Contactpage;

import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import SimpleReactValidator from "simple-react-validator";
import {toast} from "react-toastify";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {Link, useNavigate} from "react-router-dom";
import "@coreui/coreui/dist/css/coreui.css";
import './style.scss';
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { setLocalDataAsObject } from '../../utils/CoustomStorage';
import useGetProfileDetails from '../../hooks/useProfileDetails';



const LoginPage = (props) => {
    const { getUserProfile } = useGetProfileDetails()
    const api = new API()
    const push = useNavigate()

    const [value, setValue] = useState({
        mobile: '',
        password: '',
        remember: false,
    });

    const changeHandler = (e) => {
        setValue({...value, [e.target.name]: e.target.value});
        validator.showMessages();
    };

    const rememberHandler = () => {
        setValue({...value, remember: !value.remember});
    };

    const [validator] = React.useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));



    const submitForm = async(e) => {
        e.preventDefault();
        if (validator.allValid()) {
            // setValue({
            //     mobile: '',
            //     password: '',
            //     remember: false
            // });
            validator.hideMessages();
            let data = {
                mobile: value.mobile,
                password: value.password
            }
            const response = await api.post(config.login, data)
            console.log("response is", response)
            if (response.code == 200) {
                toast.success('You successfully Login on Feelings !');
                setLocalDataAsObject('token', response.data.token)
                getUserProfile()
                push('/apply');
            } else {
                toast.success('Something wen wrong !');
            }
        } else {
            validator.showMessages();
            toast.error('Empty field is not allowed!');
        }
    };
    return (
        <Grid className="loginWrapper" style={{margin: '0px', padding: '0px', minHeight: 'auto'}}>
            <Grid className="loginForm" style={{paddingTop: '10px', paddingBottom: '10px'}}>
                <h2>Sign In</h2>
                <p>Sign in to your account</p>
                <form onSubmit={submitForm}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="Mobile number"
                                value={value.mobile}
                                variant="outlined"
                                name="mobile"
                                label="Mobile number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                            {validator.message('mobile', value.mobile, 'required|mobile')}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="Password"
                                value={value.password}
                                variant="outlined"
                                name="password"
                                type="password"
                                label="Password"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                            {validator.message('password', value.password, 'required')}
                        </Grid>
                        <Grid item xs={12}>
                            <Grid className="formAction">
                                <FormControlLabel
                                    control={<Checkbox checked={value.remember} onChange={rememberHandler}/>}
                                    label="Remember Me"
                                />
                                {/* <Link to="/forgot-password">Forgot Password?</Link> */}
                            </Grid>
                            <Grid className="formFooter">
                                <Button fullWidth className="cBtnTheme" type="submit">Login</Button>
                            </Grid>
                            {/* <Grid className="loginWithSocial">
                                <Button className="facebook"><i className="fa fa-facebook"></i></Button>
                                <Button className="twitter"><i className="fa fa-twitter"></i></Button>
                                <Button className="linkedin"><i className="fa fa-linkedin"></i></Button>
                            </Grid>
                            <p className="noteHelp">Don't have an account? <Link to="/register">Create free account</Link>
                            </p> */}
                        </Grid>
                    </Grid>
                </form>
                <div className="shape-img">
                    <i className="fi flaticon-honeycomb"></i>
                </div>
            </Grid>
        </Grid>
    )
};

export default LoginPage;
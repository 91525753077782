import React, { createContext, useEffect, useState } from 'react';
import { getLocalDataAsObject } from '../../utils/CoustomStorage';
import { config } from '../../utils/apiUrl';
import { toast } from 'react-toastify';
import API from '../../utils/apiCalling';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Set initial user details
  const api = new API()

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;

import React, { useState } from 'react'
import SimpleReactValidator from 'simple-react-validator';
import { config } from '../../utils/apiUrl';
import API from '../../utils/apiCalling';
import { toast } from 'react-toastify';
import { setLocalDataAsObject } from '../../utils/CoustomStorage';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';


const ContactForm = () => {
    const push = useNavigate()
    const api = new API()
    const [forms, setForms] = useState({
        name: '',
        email: '',
        phone: '',
        net_montly_income: '',
        password: ''
    });
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));
    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = async(e) => {
        e.preventDefault();
        console.log(forms)
        if (validator.allValid()) {
            validator.hideMessages();
            let data = {
                name: forms.name,
                email: forms.email,
                mobile: forms.phone,
                applyFor: forms.apply_for,
                netMonthlyIncome: forms.net_montly_income,
                password: forms.password
            }
            Swal.fire({
                title: "Are you sure?",
                text: "Ready to take the next step!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes!"
              }).then(async(result) => {
                if (result.isConfirmed) {
            const response = await api.post(config.register, data)
            console.log("response is", response)
            if (response.code == 200) {
                Swal.fire({
                    title: "Saved!",
                    text: "Successfully Login !",
                    icon: "success"
                  });
                setLocalDataAsObject('token', response.data.token)
                push('/apply');
            } else {
                toast.success('Something wen wrong !');
            }
        }
    });
        } else {
            validator.showMessages();
        }
    };

    return (
        <form onSubmit={(e) => submitHandler(e)} className="contact-validation-active" >
            <div className="row">
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.name}
                            type="text"
                            name="name"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Your Name"
                            style={{color: 'white', borderRadius: '11px', outline:'none'}} />
                        {validator.message('name', forms.name, 'required|alpha_space')}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.email}
                            type="email"
                            name="email"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Your Email" 
                            style={{color: 'white', borderRadius: '11px', outline:'none'}}/>
                        {validator.message('email', forms.email, 'required|email')}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.phone}
                            type="phone"
                            name="phone"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Your phone" 
                            style={{color: 'white', borderRadius: '11px', outline:'none'}}/>
                        {/* {validator.message('phone', forms.phone, 'required|phone')} */}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <select
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            value={forms.apply_for}
                            type="text"
                            name="apply_for"
                            style={{ borderRadius: '11px',outline:'none'}}>
                            <option >Apply For</option>
                            <option value="credit_card">Credit Cards</option>
                            {/* <option value="overdraft">Over Draft</option>
                            <option value="loan">Loan</option> */}
                        </select>
                        {/* {validator.message('apply_for', forms.subject, 'required')} */}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.net_montly_income}
                            type="text"
                            name="net_montly_income"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Net monthly income" 
                            style={{color: 'white', borderRadius: '11px',outline:'none'}}/>
                        {/* {validator.message('net_montly_income', forms.net_montly_income, 'required|net_montly_income')} */}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.password}
                            type="password"
                            name="password"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Create password" 
                            style={{color: 'white', borderRadius: '11px',outline:'none'}}/>
                        {/* {validator.message('phone', forms.phone, 'required|phone')} */}
                    </div>
                </div>
        
            </div>
            <div className="submit-area">
                <button type="submit" className="theme-btn">Apply Now</button>
            </div>
        </form >
    )
}

export default ContactForm;
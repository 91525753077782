import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button2 } from '../../components/common/Button2';
import PayWithPhonePe from './PayWithPhonePay';
import { imgUrl } from '../../config/env';

function PayModal({globalDetails, modal, toggle, fee}, args) {

  return (
    <div>
      <Modal className="modal-dialog-centered pay-modal" isOpen={modal} toggle={()=>toggle(fee)} {...args}>
            <ModalBody >
            <h1 style={{textAlign: 'center', color: 'white', marginBottom: '20px'}}>Rs {fee}</h1>
            <div style={{textAlign: 'center'}}>
                <img src={globalDetails?.qrCodeImage && `${imgUrl}/${globalDetails.qrCodeImage}`} alt="qr" style={{width: '300px', height: '300px'}}/>
            </div>

            {/* <PayWithPhonePe globalDetails={globalDetails} fee={fee}/> */}
        </ModalBody>
        {/* <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter> */}
      </Modal>
    </div>
  );
}

export default PayModal;
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
export const CustomLoader = () =>{
    return (
        <div>
        <Modal className="modal-dialog-centered custom-loader-modal" isOpen={true}>
          <ModalBody >
                <div class="CustomLoader">
                    <div class="intern">
                    </div>
                    <div class="external-shadow">
                        <div class="central">
                        </div>
                    </div>
                </div>
          </ModalBody>
        </Modal>
    
    </div>
    )
}
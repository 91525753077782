import timg1 from '../images/team/1.jpg'
import timg2 from '../images/team/2.jpg'
import timg3 from '../images/team/3.jpg'
import timg4 from '../images/team/4.jpg'
import timg5 from '../images/team/5.jpg'
import timg6 from '../images/team/6.jpg'


const Teams = [
    {
        Id: '1',
        tImg:timg1,
        name:'Best Credit Cards',
    },
    {
        Id: '2',
        tImg:timg2,
        name:'Rewards',
    },
    {
        Id: '3',
        tImg:timg3,
        name:'Travel',
    },
    {
        Id: '4',
        tImg:timg4,
        name:'Fuel',
    },
    {
        Id: '5',
        tImg:timg5,
        name:'Cash Back',
    },
    {
        Id: '6',
        tImg:timg6,
        name:'No Annual Fee',
    }
]

export default Teams;
import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import LoginPage from '../../main-component/LoginPage';

function LoginModal({modal, toggle}, args) {
  const [forms, setForms] = useState({
    phone: '',
    password: '',
});
const changeHandler = e => {
    setForms({ ...forms, [e.target.name]: e.target.value })
};
const submitHandler = e => {
  e.preventDefault();
};
  return (
    <div>
      <Modal className="modal-dialog-centered" isOpen={modal} toggle={toggle} {...args}>
        <ModalHeader toggle={toggle}>User Login</ModalHeader>
        <ModalBody>
           <LoginPage />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default LoginModal;
import React, { useState } from 'react'
import SimpleReactValidator from 'simple-react-validator';
import { config } from '../../utils/apiUrl';
import API from '../../utils/apiCalling';
import { toast } from 'react-toastify';
import { setLocalDataAsObject } from '../../utils/CoustomStorage';
import { useNavigate } from 'react-router-dom';
import useGetProfileDetails from '../../hooks/useProfileDetails';
import Swal from 'sweetalert2';


const BankDetailsForm = ({}) => {
    const push = useNavigate()
    const { getUserProfile } = useGetProfileDetails()
    const api = new API()
    const [forms, setForms] = useState({
        accName: '',
        accountNumber: '',
        ifsc: '',
        amount: '',
        bankName: '',
    });
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));
    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = async(e) => {
        e.preventDefault();
        console.log(forms)
        // steperChange(2)
        if (validator.allValid()) {
            validator.hideMessages();
            // let data = {
            //     fatherName: forms.father_name,
            //     email: forms.email,
            //     dob: forms.dob,
            //     employmentType: forms.employment_type,
            //     gender: forms.gender,
            //     pinCode: forms.pin_code,
            //     addressLine1: forms.address,
            //     isPersonalInformation: true,
            // }
            const formData = new FormData();
            formData.append('accName', forms.accName);
            formData.append('accountNumber', forms.accountNumber);
            formData.append('ifsc', forms.ifsc);
            formData.append('amount', forms.amount);
            formData.append('bankName', '')
            formData.append('isBankDetailsFiled', true)
            Swal.fire({
                title: "Are you sure?",
                text: "Are you sure to transfer amount in bank account!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes!"
              }).then(async(result) => {
                if (result.isConfirmed) {
            const response = await api.postUpload(config.updateDetail, formData)
            console.log("response is", response)
            if (response.code == 200) {
                    window.scrollTo({
                      top: 350,
                      behavior: 'smooth' // Optional, adds smooth scrolling behavior
                    });
                Swal.fire({
                    title: "Saved!",
                    text: "Successfully saved bank details !.",
                    icon: "success"
                  });
                getUserProfile()
            } else {
                toast.success('Something wen wrong !');
            }
            }
        });
        } else {
            validator.showMessages();
        }
    };

    return (
        <form className="contact-validation-active" >
            <div className="row">
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.accName}
                            type="text"
                            name="accName"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Accoun holder name" 
                            style={{borderRadius: '30px',  color:'#fff', fontSize: '17px',outline:'none'}}
                            />
                        {validator.message('accName', forms.accName, 'required|alpha_space')}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.accountNumber}
                            type="accountNumber"
                            name="accountNumber"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Your account number" 
                            style={{borderRadius: '30px',  color:'#fff', fontSize: '17px', outline:'none'}}
                            />
                        {validator.message('accountNumber', forms.accountNumber, 'required|accountNumber')}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.ifsc}
                            type="ifsc"
                            name="ifsc"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Your IFSC" 
                            style={{borderRadius: '30px',  color:'#fff', fontSize: '17px', outline:'none'}}
                            />
                        {validator.message('ifsc', forms.ifsc, 'required|ifsc')}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.amount}
                            type="number"
                            name="amount"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Your amount" 
                            style={{borderRadius: '30px',  color:'#fff', fontSize: '17px', outline:'none', appearance: 'textfield', /* For Firefox */
                            WebkitAppearance: 'none'}}
                            />
                        {validator.message('amount', forms.amount, 'required|amount')}
                    </div>
                </div>
               
            </div>
            <div className="submit-area">
                <button type="button" onClick={submitHandler} className="theme-btn">Next</button>
            </div>
        </form >
    )
}

export default BankDetailsForm;
import React from 'react'

const CardBanner = () => {
  return (
      <div className=" card_banner">
        <div className="col-md-12 p-3">
            <h1 style={{color:'white'}}>Get Your Card Today*</h1>
            <h4 style={{color:'white'}}>Get your free <b>NaviCards</b> Free Credit Card </h4>
            <div className='d-flex justify-content-center'>
            <img src='./images/offer.png' alt='no image found' className='offer_img'/>
            </div>
        </div>
      </div>
  );
};

export default CardBanner
